/** @jsxImportSource @emotion/react */
import { Interpolation } from "@emotion/react";
import { Item, Stack, Text, Theme } from "@nettbureau/ui";
import { CustomColumn, MappedSite } from "../../store/globalState/globalState";

export const CardCell = ({ name, data }: MappedSite, column: CustomColumn) => {
  const columnStyles: Interpolation<Theme> = {
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  };
  switch (column.id) {
    case "site":
      return (
        <Stack vertical css={columnStyles}>
          <Text variant="body1">{name}</Text>
        </Stack>
      );
    case "redirects":
      return (
        <Stack vertical css={columnStyles}>
          {data?.redirects?.properties?.canonical &&
            data?.redirects?.properties?.canonical?.external && (
              <Stack vertical css={columnStyles}>
                <Text variant="body2">External</Text>
                <Text variant="body2">
                  {data?.redirects?.properties?.canonical?.url}
                </Text>
              </Stack>
            )}

          {data?.redirects?.properties?.canonical &&
            !data?.redirects?.properties?.canonical?.external && (
              <Stack vertical css={columnStyles}>
                <Text variant="body2">Canonical</Text>
                <Text variant="body2">
                  {data?.redirects?.properties?.canonical?.url}
                </Text>
              </Stack>
            )}
        </Stack>
      );
    case "email":
      return (
        <Stack vertical css={columnStyles}>
          {data?.email?.properties?.gmail && <Text color="blue">Gmail</Text>}

          {data?.email?.properties?.domeneshop && (
            <Text color="blue">Domeneshop</Text>
          )}

          {data?.email?.properties?.frontmail && (
            <Text color="blue">FrontMail</Text>
          )}

          {data?.email?.properties?.mailgun && (
            <Text color="blue">Mailgun</Text>
          )}
        </Stack>
      );
    case "server":
      return (
        <Stack vertical css={columnStyles}>
          {data?.hosting?.properties?.current && (
            <Text color="green">{data?.hosting?.properties?.current}</Text>
          )}
          {data?.hosting?.properties?.data?.name && (
            <Text color="green">{data?.hosting?.properties?.data?.name}</Text>
          )}
          {data?.hosting?.properties?.parked && (
            <Text color="gray">Parked</Text>
          )}
        </Stack>
      );
    case "cdn":
      return (
        <Stack vertical css={columnStyles}>
          {data?.hosting?.properties?.current && (
            <Item>
              {data?.hosting?.properties?.cdn?.name && (
                <Text color="yellow" variant="body1">
                  {data?.hosting?.properties?.cdn?.name}
                </Text>
              )}
            </Item>
          )}
        </Stack>
      );
    case "surveillance":
      return (
        <Stack vertical css={columnStyles}>
          {data?.surveillance?.properties?.pingdom && (
            <Text color="red">Pingdom</Text>
          )}

          {data?.surveillance?.properties?.betteruptime && (
            <Text color="red">Better Uptime</Text>
          )}
        </Stack>
      );
    case "postmannen":
      return (
        <Stack vertical css={columnStyles}>
          {data?.postmannen?.properties?.["leadmail.no"]?.mailgun && (
            <Text color="red">Mailgun</Text>
          )}

          {data?.postmannen?.properties?.["leadmail.no"]?.bulksms && (
            <Text color="red">
              SMS: {data.postmannen.properties["leadmail.no"].bulksms}
            </Text>
          )}
        </Stack>
      );

    default:
      return null;
  }
};
