import { SortDescriptor } from '@react-types/shared';

export function sortMultiCompare<T>(a: T, b: T, sorts: SortDescriptor[]): number {
  const select = sorts[0]?.column;
  const order = sorts[0]?.direction;
  if (!select || !order) {
    return 0;
  }
  const aValue = a[select as keyof T];
  const bValue = b[select as keyof T];

  if (aValue < bValue) {
    return order === 'ascending' ? -1 : 1;
  }
  if (aValue > bValue) {
    return order === 'ascending' ? 1 : -1;
  }
  if (sorts.length > 1) {
    const remainingSorts = sorts.slice(1);
    return sortMultiCompare(a, b, remainingSorts);
  }
  return 0;
}