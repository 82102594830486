import {
  Cell,
  Column,
  Row,
  Table as NuiTable,
  TableBody,
  TableHeader,
} from "@nettbureau/ui";

import { useTable, UseTableProps } from "../hooks/useTable";

type TableProps<T> = UseTableProps<T>;

export function Table<T>(props: TableProps<T & { key: string }>) {
  const { renderCell, defaultSortDescriptor, ...otherProps } = props;
  const { sortDescriptor, onSortChange, columns, data, loading } =
    useTable(props);

  return (
    <NuiTable
      {...otherProps}
      sortDescriptor={sortDescriptor}
      onSortChange={onSortChange}
    >
      <TableHeader>
        {columns.map((column) => {
          const { headerVisible = true, sortable = false } = column;

          return (
            <Column
              key={column.id}
              hideHeader={!headerVisible}
              allowsSorting={sortable}
              width={column.width ?? undefined}
            >
              {column.title}
            </Column>
          );
        })}
      </TableHeader>
      <TableBody loadingState={loading}>
        {data.map((row, rowIndex) => (
          <Row key={row.key}>
            {columns.map((column, columnIndex) => (
              <Cell
                key={column.id + "_" + String(rowIndex) + String(columnIndex)}
              >
                {renderCell(row, column)}
              </Cell>
            ))}
          </Row>
        ))}
      </TableBody>
    </NuiTable>
  );
}
