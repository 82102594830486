import { useAuth0 } from "@auth0/auth0-react";
import { Card, Heading, Item, ProgressCircle, Stack } from "@nettbureau/ui";
import { useEffect } from "react";

export const LoginPage = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const path = "/";

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      });
    };
    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect, path]);

  return (
    <Stack style={{ height: "100vh" }} align="center" justify="center">
      <Card>
        <Stack vertical gap={4}>
          <Item>
            <Heading gutter variant="h3">
              Domain status
            </Heading>

            <ProgressCircle />
          </Item>
        </Stack>
      </Card>
    </Stack>
  );
};
