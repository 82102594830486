import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "@nettbureau/ui";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { App } from "./App";
import { store } from "./store/store";

ReactDOM.render(
    <Auth0Provider
      audience="https://status.nettbureau.dev/api/"
      domain="nettbureau-internal.eu.auth0.com"
      clientId="zx9FPRyRLpksT9cNsz1gLUEUYo9xhEsV"
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <Provider>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
      </Provider>
    </Auth0Provider>,
  document.getElementById("root")
);
