/** @jsxImportSource @emotion/react */
import { useAuth0 } from "@auth0/auth0-react";
import {
  Card,
  Dialog,
  DialogContent,
  DialogHeader,
  Item,
  ProgressCircle,
  Stack,
  TabItem,
  TabList,
  TabPanels,
  Tabs,
  TextField,
  useStyles,
  Text,
} from "@nettbureau/ui";
import { FC, useEffect, useMemo, useState } from "react";
import { CardCell } from "../components/status-page/card-cell";
import { Columns } from "../components/status-page/columns";
import { Filters } from "../components/status-page/filters";
import { Table } from "../components/table";
import {
  CustomColumn,
  fetchSites,
  Filter,
  MappedSite,
  selectFilters,
  selectLoading,
  selectSites,
  setVisibleColumns,
  Site,
} from "../store/globalState/globalState";
import { useAppDispatch, useAppSelector } from "../store/hooks";

const tableColumns: CustomColumn[] = [
  {
    title: "Site",
    id: "site",
    visible: true,
    sortable: true,
  },
  {
    title: "Redirects",
    id: "redirects",
    visible: false,
  },
  {
    title: "Postmannen",
    id: "postmannen",
    visible: true,
  },
  {
    title: "E-mail",
    id: "email",
    visible: true,
  },
  {
    title: "Server",
    id: "server",
    visible: true,
  },
  {
    title: "CDN",
    id: "cdn",
    visible: true,
  },
  {
    title: "Surveilance",
    id: "surveillance",
    visible: true,
  },
  {
    title: "Actions",
    id: "actions",
    visible: false,
  },
];
type DynamicFilter = {
  value?: boolean | undefined;
} & Filter;

export const StatusPage: FC = () => {
  const [show, setShow] = useState(false);
  const [site, setSite] = useState<null | Site>(null);
  const [filteredSites, setFilteredSites] = useState<Record<string, Site>>({});

  const [search, setSearch] = useState("");

  const { getIdTokenClaims } = useAuth0();

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      dispatch(fetchSites(await getIdTokenClaims()));
    })();
  }, [dispatch, getIdTokenClaims]);

  const loading = useAppSelector(selectLoading);
  const sites = useAppSelector(selectSites);
  const filters = useAppSelector(selectFilters);
  const [dynamicFilters, setDynamicFilters] =
    useState<DynamicFilter[]>(filters);
  const hasParkedFilter = useMemo(() => {
    return Object.values(dynamicFilters).some(
      (filter) => filter?.filterKey === "parked" && filter?.value
    );
  }, [dynamicFilters]);

  const mappedSites = useMemo(
    () =>
      Object.keys(filteredSites)
        .map((site, index) => {
          const currentSite = sites[site];
          return {
            key: site,
            name: site,
            data: currentSite,
          } as MappedSite;
        })
        .filter(({ name }) => {
          return name.toLowerCase().includes(search.toLowerCase());
        }),
    [filteredSites, sites, search]
  );

  const tableCss = useStyles(({ sizes }) => ({
    height: `calc(100vh - ${sizes.scale[40]}px)`,
    width: "100%",
    display: "flex",
  }));
  return (
    <Stack align="start" gap={4}>
      <Stack vertical gap={4}>
        <Filters
          setFilteredSites={setFilteredSites}
          setFilters={setDynamicFilters}
          sites={sites}
          filters={filters}
        />
        <Columns
          columns={tableColumns}
          setVisibleColumns={(c) => dispatch(setVisibleColumns(c))}
        />
      </Stack>

      <Item grow>
        <Stack vertical align="stretch">
          <Dialog
            maxWidth={"lg"}
            dismissible
            onDismiss={() => setShow(false)}
            open={show}
          >
            <DialogHeader>Technical details</DialogHeader>
            <DialogContent>
              {site !== null && (
                <Tabs>
                  <TabList>
                    {Object.keys(site)
                      .filter((h) => {
                        return h !== "filters";
                      })
                      .map((h, index) => {
                        return <TabItem key={index}>{h.toUpperCase()}</TabItem>;
                      })}
                  </TabList>
                  <TabPanels>
                    {Object.keys(site)
                      .filter((h) => {
                        return h !== "filters";
                      })
                      .map((h, index) => {
                        return (
                          <TabItem key={index}>
                            <Card
                              css={{
                                height: 800,
                                width: "100%",
                                overflow: "auto",
                              }}
                              color="gray"
                            >
                              <pre>{JSON.stringify(site[h], null, 2)}</pre>
                            </Card>
                          </TabItem>
                        );
                      })}
                  </TabPanels>
                </Tabs>
              )}
            </DialogContent>
          </Dialog>

          <Stack vertical align="stretch">
            <TextField
              value={search}
              onChange={(e) => {
                setSearch(e.formattedValue);
              }}
              clearable
              width={"100%"}
              label={"Site"}
              placeholder={"Search for a site"}
            />
            {loading ? (
              <Text variant="caption">Loading...</Text>
            ) : (
              <Text variant="caption">
                Showing{" "}
                {
                  Object.values(filteredSites).filter(
                    (s) => hasParkedFilter || !s.filters.includes("parked")
                  ).length
                }{" "}
                of{" "}
                {
                  Object.values(sites).filter(
                    (s) => hasParkedFilter || !s.filters.includes("parked")
                  ).length
                }
              </Text>
            )}
            <Item grow css={tableCss}>
              <Table
                aria-label="Site table"
                overflowMode="wrap"
                css={{
                  flex: "1 1 0%",
                  height: "100%",
                }}
                renderLoadingState={() => <ProgressCircle />}
                onAction={(selected) => {
                  if (selected) {
                    let site = Object.keys(sites)?.find(
                      (s) => s === selected?.toString()
                    );
                    if (site) {
                      setSite(sites[site]);
                      setShow(true);
                    }
                  }
                }}
                renderEmptyState={() => <span>No data found :(</span>}
                loading={loading}
                stickyHeader={true}
                columns={tableColumns}
                data={mappedSites}
                renderCell={CardCell}
                defaultSortDescriptor={{
                  column: "site",
                  direction: "ascending",
                }}
              />
            </Item>
          </Stack>
        </Stack>
      </Item>
    </Stack>
  );
};
