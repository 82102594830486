import { Heading, Stack, Checkbox, Label } from "@nettbureau/ui";
import { FC, useEffect, useState } from "react";
import { Filter, Site } from "../../store/globalState/globalState";

export interface IFilters {
  filters: Filter[];
  setFilteredSites(sites: Record<string, Site>): void;
  setFilters(filters: DynamicFilter[]): void;
  sites: Record<string, Site>;
}

type DynamicFilter = {
  value: boolean | undefined;
} & Filter;

function matchFilters(filters: DynamicFilter[], site: Site) {
  return filters.map((filter) => {
    const foundFilter = site.filters.find((f) => {
      return f === filter.filterKey;
    });

    return foundFilter ? true : false;
  });
}

function filter(sites: Record<string, Site>, dynamicFilters: DynamicFilter[]) {
  const currentFilters = dynamicFilters.filter((filter) => {
    return filter.value === true;
  });

  if (currentFilters.length === 0) {
    return sites;
  }

  const filteredSites: Record<string, Site> = {};

  Object.keys(sites).forEach((site) => {
    const matchedFilters = matchFilters(currentFilters, sites[site]);

    if (!matchedFilters.includes(false)) {
      filteredSites[site] = sites[site];
    }
  });

  return filteredSites;
}

function buildDynamicFilters(filters: Filter[]) {
  return filters.map((filter) => {
    return {
      ...filter,
      value: filter.checked ? true : false,
    };
  });
}

export const Filters: FC<IFilters> = ({
  filters,
  sites,
  setFilters,
  setFilteredSites,
}) => {
  const [dynamicFilters, setDynamicFilters] = useState<DynamicFilter[]>([]);

  useEffect(() => {
    setDynamicFilters(buildDynamicFilters(filters));
  }, [filters]);

  useEffect(() => {
    setFilters(dynamicFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicFilters]);

  useEffect(
    () => setFilteredSites(filter(sites, dynamicFilters)),
    [dynamicFilters, sites, setFilteredSites]
  );

  return (
    <Stack vertical gap={4}>
      <Heading variant="h6">Filters</Heading>

      <Stack vertical gap={4}>
        {dynamicFilters.map((filter, index) => {
          return (
            <Stack gap={4} key={filter.label}>
              <Checkbox
                onChange={(e) => {
                  setDynamicFilters(
                    dynamicFilters.map((filter, currentFilter) => {
                      if (currentFilter === index) {
                        filter.value = e;
                      }
                      return filter;
                    })
                  );
                }}
                checked={filter.value}
                aria-label={filter.label}
              >
                <Label>{filter.label}</Label>
              </Checkbox>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
