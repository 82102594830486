import { IdToken } from "@auth0/auth0-react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getApiUrl } from "../../config/config";
import type { RootState } from "../store";

export type Site = {
  [key: string]: HealthCheck;
} & { filters: string[]; name: string };

export type MappedSite = {
  name: string;
  key: string;
  data: Site;
};

export type HealthCheck = {
  success: boolean;
  message: string;
  properties: {
    [key: string]: Record<string, string | number | boolean>;
  };
};

export type Filter = {
  checked?: boolean;
  label: string;
  filterKey: string;
};

export interface CustomColumn {
  id: string;
  title: string;
  visible?: boolean;
  headerVisible?: boolean;
  sortable?: boolean;
  width?: number | string;
}

export interface GlobalState {
  loading: boolean;
  filters: Filter[];
  columnVisibility: Record<string, boolean>;
  sites: Record<string, Site>;
}

const initialState: GlobalState = {
  loading: false,
  filters: [],
  columnVisibility: {},
  sites: {},
};

export const counterSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload;
    },
    setSites: (state, action: PayloadAction<Record<string, Site>>) => {
      state.sites = action.payload;
    },
    setColumnVisibility: (
      state,
      action: PayloadAction<Record<string, boolean>>
    ) => {
      state.columnVisibility = action.payload;
    },
  },
});

export const { setSites, toggleLoading, setFilters, setColumnVisibility } =
  counterSlice.actions;

export const selectSites = (state: RootState) => state.global.sites;
export const selectLoading = (state: RootState) => state.global.loading;
export const selectFilters = (state: RootState) => state.global.filters;
export const selectVisibleColumns = (state: RootState) =>
  state.global.columnVisibility;

export default counterSlice.reducer;

export const fetchSites = (idToken?: IdToken) => {
  return async (dispatch: any) => {
    dispatch(toggleLoading());

    if (!idToken) {
      throw Error("not authenticated - or id token missing");
    }

    const response = await fetch(`${getApiUrl()}/status`, {
      headers: {
        Authorization: "Bearer " + idToken.__raw,
      },
    });

    const decodedResponse = await response.json();

    dispatch(setFilters(decodedResponse.filters));
    dispatch(setSites(decodedResponse.sites));

    dispatch(toggleLoading());
  };
};

export const setVisibleColumns = (
  columnVisibility: Record<string, boolean>
) => {
  return async (dispatch: any) => {
    dispatch(setColumnVisibility(columnVisibility));
  };
};
