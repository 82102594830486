export const PRODUCTION_API_URL = "https://status.nettbureau.dev/api";
export const DEVELOPMENT_API_URL = "http://localhost:8000/api";

export function getApiUrl() {
  if (window.location.href.includes("localhost")) {
    return DEVELOPMENT_API_URL;
  } else {
    return PRODUCTION_API_URL;
  }
}
