import type { TableProps as NuiTableProps } from "@nettbureau/ui";
import { LoadingState } from "@react-types/shared";

import { SortDescriptor } from "@react-types/shared";
import { ReactNode, useCallback, useState } from "react";

import {
  CustomColumn,
  selectVisibleColumns,
} from "../store/globalState/globalState";
import { useAppSelector } from "../store/hooks";
import { sortMultiCompare } from "../utils/sort";

export type UseTableProps<T> = Omit<NuiTableProps<T>, "children"> & {
  data: T[];
  columns: CustomColumn[];
  defaultSortDescriptor: SortDescriptor;
  renderCell: (item: T, column: CustomColumn) => ReactNode;
  loading?: boolean;
};
export const useTable = <T>(props: UseTableProps<T>) => {
  const { data = [], columns = [], defaultSortDescriptor } = props;
  const visibleColumnsState = useAppSelector(selectVisibleColumns);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>(
    defaultSortDescriptor
  );
  const getData = () => {
    return dataSort(data);
  };
  const visibleColumns = () => {
    let cols = columns.filter((column) => column.visible),
      filteredCols: CustomColumn[] = [];
    if (Object.keys(visibleColumnsState).length > 0) {
      filteredCols = columns.filter((column) => visibleColumnsState[column.id]);
    }
    // If no columns are selected, show all columns
    if (filteredCols.length === 0) {
      filteredCols = cols;
    }
    return filteredCols;
  };
  const dataSort = useCallback(
    (data: T[]) => {
      return data.sort((a, b) => {
        return sortMultiCompare(a, b, [sortDescriptor]);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, sortDescriptor, sortDescriptor.column, sortDescriptor.direction]
  );

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    return getData();
  };

  return {
    columns: visibleColumns(),
    data: getData(),
    sortDescriptor,
    onSortChange,
    loading: props.loading ? "loading" : ("idle" as LoadingState),
  };
};
