/** @jsxImportSource @emotion/react */
import { useAuth0, User } from "@auth0/auth0-react";
import { Container, useStyles } from "@nettbureau/ui";
import { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";
import { LoginPage } from "./pages/login-page";
import { StatusPage } from "./pages/status-page";

function isNettbureau(user?: User) {
  return user?.email?.split("@")[1].includes("nettbureau");
}

export const App: FC = () => {
  const containerStyles = useStyles({
    marginTop: 25,
  });

  const { user, isAuthenticated } = useAuth0();

  if (!isAuthenticated || !isNettbureau(user)) {
    return <LoginPage />;
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Header />

      <Container maxWidth="lg" css={containerStyles}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<StatusPage />} />
          </Routes>
        </BrowserRouter>
      </Container>
    </div>
  );
};
