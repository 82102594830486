import { Heading, Stack, Checkbox, Label, Button } from "@nettbureau/ui";
import { FC, useEffect, useState } from "react";
import {
  CustomColumn,
  selectVisibleColumns,
} from "../../store/globalState/globalState";
import { useAppSelector } from "../../store/hooks";

export interface IColumns {
  columns: CustomColumn[];
  setVisibleColumns: (columns: Record<string, boolean>) => void;
}

export const Columns: FC<IColumns> = ({ columns, setVisibleColumns }) => {
  const visibleColumnsState = useAppSelector(selectVisibleColumns);
  const [dynamicColumns, setDynamicColumns] = useState<CustomColumn[]>(columns);

  const getDefaultColumns = (columns: CustomColumn[]) => {
    return columns.reduce((acc, column) => {
      if (typeof column.visible === "boolean") {
        acc[column.id] = column.visible;
      }
      return acc;
    }, {} as Record<string, boolean>);
  };

  useEffect(() => {
    setDynamicColumns(
      dynamicColumns.map((column) => {
        return {
          ...column,
          visible: Object.keys(visibleColumnsState).includes(column.id)
            ? visibleColumnsState[column.id]
            : column.visible,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleColumnsState]);

  return (
    <Stack vertical gap={4}>
      <Heading variant="h6">Columns</Heading>

      <Button
        onClick={() => setVisibleColumns(getDefaultColumns(columns))}
        disabled={Object.keys(visibleColumnsState).length === 0}
      >
        Reset
      </Button>

      <Stack vertical gap={4}>
        {dynamicColumns.map((column, index) => {
          return (
            <Stack gap={4} key={column.id}>
              <Checkbox
                onChange={(e) => {
                  setVisibleColumns({
                    ...getDefaultColumns(dynamicColumns),
                    [column.id]: e,
                  });
                }}
                checked={column.visible}
                aria-label={column.title}
              >
                <Label>{column.title}</Label>
              </Checkbox>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
