/** @jsxImportSource @emotion/react */
import {
  HeaderBar,
  Stack,
  Item,
  Button,
  Text,
  useStyles,
  Container,
} from "@nettbureau/ui";
import { FC } from "react";

export const Header: FC = () => {
  const headerBarStyles = useStyles({
    borderRadius: 0,
  });

  return (
    <div>
      <HeaderBar css={headerBarStyles} color="gray">
        <Container maxWidth="lg">
          <Stack gap={2}>
            <Item grow>
              <Text as="div" variant="h5">
                Nettbureau domains
              </Text>
            </Item>
            <Item>
              <Button color="gray" variant="plain" as="a" href="/">
                Status
              </Button>
            </Item>
            <Item>
              <Button color="gray" variant="plain" as="a" href="/watcher">
                Watcher
              </Button>
            </Item>
          </Stack>
        </Container>
      </HeaderBar>
    </div>
  );
};
